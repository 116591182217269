import React from "react"
import PropTypes from "prop-types"
import Box from "common/components/Box"
import Text from "common/components/Text"
import Heading from "common/components/Heading"
import Button from "common/components/Button"
import Input from "common/components/Input"
import axios from "axios"
import { Spinner } from "reactstrap"

import Container from "common/components/UI/Container"

import NewsletterWrapper, { ContactFormWrapper } from "./newsletter.style"
import { useState } from "react"

const Newsletter = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
}) => {
  const [name, setName] = useState("")
  const [Last, setLastName] = useState("")
  const [Email, setEmail] = useState("")
  const [Phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [respone, setResponse] = useState("")
  const handleSend = () => {
    setLoading(true)

    axios
      .post(
        `https://dayra-opmx.onrender.com/contactUs/contactUs`,
        {
          message: message,
          email: Email,
          firstName: name,
          lastName: Last,
          phone: Phone,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res)
        setLoading(false)
        setResponse(res.data.message)
        console.log(res.data.message)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        // if (e.response) {
        // 	if (e.response.data && e.response.data.includes("E11000")) {
        // 		setError({ email: "Email already in use" })
        // 	}
        // }
      })
  }
  return (
    <Box {...sectionWrapper} as="section">
      <NewsletterWrapper>
        <Container>
          <Box {...textArea}>
            <Heading
              content="Contact Us"
              {...title}
              style={{ color: "black" }}
            />
            <Text
              content={
                <>
                  <li>Email:</li>
                  <li>Phone Number:</li>
                  <li>Address:</li>
                </>
              }
              {...description}
              style={{ color: "black" }}
            />
          </Box>
          <Box {...buttonArea}>
            <ContactFormWrapper>
              {/* <input inputType="email" className="email_input"></input> */}
              <Input
                inputType="email"
                label="First Name"
                iconPosition="right"
                isMaterial={true}
                className="email_input"
                onChange={(e) => {
                  setName(e)
                }}
              />
              <Input
                inputType="email"
                label="Last Name"
                iconPosition="right"
                isMaterial={true}
                className="email_input"
                onChange={(e) => {
                  setLastName(e)
                }}
              />
            </ContactFormWrapper>
            <ContactFormWrapper>
              <Input
                inputType="email"
                label="Email address"
                iconPosition="right"
                isMaterial={true}
                onChange={(e) => {
                  setEmail(e)
                }}
                className="email_input"
              />
              <Input
                inputType="number"
                label="Phone Number"
                iconPosition="right"
                isMaterial={true}
                onChange={(e) => {
                  setPhone(e)
                }}
                className="email_input"
              />
            </ContactFormWrapper>
            <ContactFormWrapper>
              <Input
                style={{
                  backgroundColor: "transparent",
                  borderColor: "#484848",
                }}
                onChange={(e) => {
                  setMessage(e)
                }}
                inputType="textarea"
                label="Message"
                iconPosition="right"
                isMaterial={true}
                className="email_input"
              />
            </ContactFormWrapper>
            <ContactFormWrapper>
              <Button
                {...buttonStyle}
                title={loading ? <Spinner /> : "Send"}
                onClick={(e) => {
                  e.preventDefault()
                  handleSend()
                }}
              />{" "}
              {respone && (
                <>
                  <p style={{ color: "green", marginLeft: "30px" }}>
                    {respone}
                  </p>
                </>
              )}
            </ContactFormWrapper>
          </Box>
        </Container>
      </NewsletterWrapper>
    </Box>
  )
}

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
}

Newsletter.defaultProps = {
  sectionWrapper: {},
  textArea: {
    mb: ["40px", "40px", "40px", "0", "0"],
    pr: ["0", "0", "0", "80px", "100px"],
  },
  title: {
    fontSize: ["18px", "20px", "22px", "24px", "26px"],
    fontWeight: "500",
    color: "#fff",
    lineHeight: "1.34",
    mb: ["14px", "14px", "14px", "14px", "13px"],
    textAlign: ["center", "center", "center", "left", "left"],
    letterSpacing: "-0.025em",
  },
  description: {
    fontSize: ["14px", "14px"],
    maxWidth: ["100%", "400px"],
    fontWeight: "400",
    color: "#fefefe",
    lineHeight: "1.7",
    mb: 0,
    textAlign: ["center", "center", "center", "left", "left"],
  },
  buttonArea: {
    zIndex: 1,
  },
  buttonStyle: {
    type: "button",
    fontSize: "14px",
    fontWeight: "700",
    pl: "30px",
    pr: "30px",
  },
}

export default Newsletter
